import React, {createContext, ReactNode, useEffect, useState} from 'react';
import {useDigitalProductApi} from "../../core/hooks/use-digital-product-api";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import {Pricing} from "../../core/models/interfaces/pricing.interface";
import useAuth from "../../auth/hooks/use-auth";
import {DigitalProductFormBody} from "../models/interfaces/digital-product-form-body.interface";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import {UpdateDigitalProduct} from "../../core/models/interfaces/update-digital-product-request.interface";
import FileHelper from "../../../utils/file-helper";
import Base64Helper from "../../../utils/base64-helper";
import {DigitalProductFilterProvider} from "./digital-product-filter-context";
import {DateHelper} from "../../../utils/date-helper";
import {DigitalProductCategory} from "../../core/models/interfaces/digital-product-category.interface";
import {useDigitalProductCategoryApi} from "../../core/hooks/use-digital-product-category-api";
import {useDigitalProductFilter} from "../hooks/use-digital-product-filter.hook";

export interface SessionCreatorToolsContextProps {
    products: DigitalProduct[];
    productCategories: DigitalProductCategory[];
    setProductCategories: (categories: DigitalProductCategory[]) => void;
    editedCategory: DigitalProductCategory | null;
    setEditedCategory: (category: DigitalProductCategory | null) => void;
    contactPrices: Pricing | null;
    setContactPrices: (value: Pricing | null) => void;
    initLoading: boolean;
    error: string | null;
    fetchProducts: () => Promise<void>;
    fetchContactPrices: () => Promise<void>;
    forceReload: () => Promise<void>;
    deleteProduct: (productId: string) => Promise<void>
    restoreProduct: (productId: string) => Promise<void>
    addNewProduct: (body: DigitalProductFormBody) => Promise<void>
    editProduct: (productId: string, body: UpdateDigitalProduct) => Promise<void>
    deleteCategory: (categoryId: string) => Promise<void>
    editCategory: (categoryId: string, nameCategory: string) => Promise<void>
    createCategory: (nameCategory: string) => Promise<void>
    changeOrdinalNumberCategory: (categoryId: string, newOrdinalNumber: number) => Promise<void>
    saveAllCategories: (updatedCategories: Array<DigitalProductCategory>) => Promise<void>
    fetchInitCategories: () => Promise<void>
}

interface SessionCreatorToolsProviderProps {
    children: ReactNode;
    initFetch?: boolean;
}

export const SessionCreatorToolsContext = createContext<SessionCreatorToolsContextProps | undefined>(undefined);

export const SessionCreatorToolsProvider = (props: SessionCreatorToolsProviderProps) => {
    const [products, setProducts] = useState<DigitalProduct[]>([]);
    const [productCategories, setProductCategories] = useState<DigitalProductCategory[]>([]);
    const [editedCategory, setEditedCategory] = useState<DigitalProductCategory | null>(null);
    const [contactPrices, setContactPrices] = useState<Pricing | null>(null);
    const [initLoading, setInitLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const {currentUser, hasAnyAuthorities} = useAuth();

    const {children, initFetch = false} = props;
    const {
        getOwnerDigitalProducts,
        getContactPrices,
        deleteDigitalProduct,
        createDigitalProduct,
        updateDigitalProduct,
        restoreDigitalProduct,
    } = useDigitalProductApi();
    const {
        getAllCategories,
        deleteDigitalProductCategory,
        editDigitalProductCategory,
        createDigitalProductCategory,
        changeOrdinalNumber,
        editAllCategories,
        initCategories,
    } = useDigitalProductCategoryApi();

    const fetchProducts = async () => {
        setError(null);
        try {
            const products = await getOwnerDigitalProducts(currentUser?.id!);
            setProducts(products);
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    };

    const deleteProduct = async (productId: string) => {
        setError(null);
        try {
            await deleteDigitalProduct(productId);
            setProducts(prevProducts => {
                return prevProducts.map(product => {
                    if (product.id === productId) {
                        const dateToPermanentlyDelete: Date = DateHelper.addDays(new Date(), 30);
                        const formattedDateToPermanentlyDelete: string = DateHelper.formatDate(dateToPermanentlyDelete, "YYYY-MM-DDTHH:mm:ss");

                        return {...product, deleted: formattedDateToPermanentlyDelete}
                    }
                    return product
                })
            });
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    };

    const restoreProduct = async (productId: string) => {
        setError(null);
        try {
            await restoreDigitalProduct(productId);
            setProducts(prevProducts => {
                return prevProducts.map(product => {
                    return product.id === productId
                        ? {
                            ...product,
                            deleted: null,
                            category: {
                                ...product.category,
                                name: productCategories.find(c => c.id === product.category.id)?.name ?? ""
                            }
                        }
                        : product
                })
            });
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    };

    const addNewProduct = async (body: DigitalProductFormBody) => {
        setError(null);
        try {
            await createDigitalProduct(body);
            await fetchProducts();
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    };

    const editProduct = async (productId: string, body: UpdateDigitalProduct) => {
        setError(null);
        try {
            await updateDigitalProduct(productId, body);
            const newProductImageBase64 = body.productPicture ? await FileHelper.convertFileToBase64(body.productPicture) : null

            setProducts((prevState: DigitalProduct[]) =>
                prevState.map(product =>
                    product.id === productId
                        ? {
                            ...product,
                            id: productId,
                            price: body.price || product.price,
                            name: body.name || product.name,
                            productPicture: Base64Helper.removeBase64Prefix(newProductImageBase64) || product.productPicture,
                            aliasName: body.aliasName || product.aliasName,
                            description: body.description || product.description,
                            category: {
                                ...product.category,
                                id: body.categoryId,
                                name: productCategories.find(c => c.id === body.categoryId)?.name! ?? ""
                            }
                        }
                        : product
                )
            );
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    };

    const fetchInitCategories = async() => {
        try{
            await initCategories();
            await fetchProducts();
        } catch (err: any) { 
            setError(err.message);
            throw err;
        }
    }

    const fetchContactPrices = async () => {
        setError(null);
        try {
            const prices = await getContactPrices();
            setContactPrices(prices);
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    };

    const fetchCategories = async () => {
        setError(null);
        try {
            const categories = await getAllCategories();
            setProductCategories(categories);
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    };

    const deleteCategory = async (categoryId: string): Promise<void> => {
        setError(null);
        try {
            await deleteDigitalProductCategory(categoryId);
            setProductCategories(prevState => prevState.filter(c => c.id !== categoryId));
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    }

    const editCategory = async (categoryId: string, nameCategory: string): Promise<void> => {
        setError(null);
        try {
            await editDigitalProductCategory({
                name: nameCategory,
                id: categoryId,
            })
            setProductCategories(prevState => prevState.map(c =>
                c.id === categoryId
                    ? {id: categoryId, name: nameCategory, ordinalNumber: c.ordinalNumber}
                    : c
            ));
            setEditedCategory(null);
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    }

    const createCategory = async (nameCategory: string): Promise<void> => {
        setError(null);
        try {
            const newCategoryId: string = await createDigitalProductCategory({name: nameCategory,})
            setProductCategories(prevState => [
                ...prevState,
                {name: nameCategory, id: newCategoryId, ordinalNumber: prevState.length + 1}
            ])
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    }

    const changeOrdinalNumberCategory = async (categoryId: string, newOrdinalNumber: number): Promise<void> => {
        setError(null);
        try {
            await changeOrdinalNumber({id: categoryId, ordinalNumber: newOrdinalNumber});

            setProductCategories(prevState => prevState.map(cat => {
                if (cat.id === categoryId) {
                    return {...cat, ordinalNumber: newOrdinalNumber};
                } else if (cat.ordinalNumber === newOrdinalNumber) {
                    return {...cat, ordinalNumber: prevState.find(c => c.id === categoryId)!.ordinalNumber};
                }
                return cat;
            }));
        } catch (err: any) {
            setError(err.message);
            throw err;
        }
    };

    const saveAllCategories = async (updatedCategories: Array<DigitalProductCategory>): Promise<void> => {
        setError(null);
        try {
            await editAllCategories(updatedCategories);
            setProductCategories(updatedCategories);
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    }

    const fetchData = async (): Promise<void> => {
        setInitLoading(true);
        setError(null);
        try {
            const promises = [fetchProducts()];

            if (hasAnyAuthorities([UserAuthority.CONTENT_CREATOR])) {
                promises.push(fetchContactPrices());
            }
            promises.push(fetchCategories());

            await Promise.all(promises);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setInitLoading(false);
        }
    };

    useEffect(() => {
        if (initFetch) {
            fetchData();
        }
    }, [initFetch]);

    const forceReload = async () => {
        return await fetchData();
    };

    return (
        <SessionCreatorToolsContext.Provider
            value={{
                products,
                productCategories,
                editedCategory,
                contactPrices,
                initLoading,
                error,
                fetchProducts,
                fetchContactPrices,
                forceReload,
                deleteProduct,
                addNewProduct,
                editProduct,
                setContactPrices,
                restoreProduct,
                setProductCategories,
                setEditedCategory,
                deleteCategory,
                createCategory,
                editCategory,
                changeOrdinalNumberCategory,
                saveAllCategories,
                fetchInitCategories
            }}
        >
            <DigitalProductFilterProvider products={products}>
                {children}
            </DigitalProductFilterProvider>
        </SessionCreatorToolsContext.Provider>
    );
};
