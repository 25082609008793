import React, {ReactNode, useEffect, useMemo, useState} from "react";
import {useModal} from "../hooks/use-modal";
import {ModalIds} from "../models/enums/modal-ids.enum";
import {ReactComponent as XMarkIcon} from "@assets/icons/cross.svg";
import {animated, useTransition} from "@react-spring/web";
import ReactDOM from "react-dom";
import {ModalPosition} from "../models/enums/modal-positions.enum";
import {useMediaQuery} from "react-responsive";
import {getPositionCSS} from "../helpers/get-position-css";
import {classNames} from "../../../utils/class-names";
import {CloseButtonPosition} from "../models/enums/close-button-positions.enum";

interface AppModalProps {
    id: ModalIds;
    children?: ReactNode;
    disableScroll?: boolean;
    appendTo?: string | React.RefObject<HTMLElement> | null; // Ref or string
    zIndex?: number;
    mobilePosition?: ModalPosition;
    desktopPosition?: ModalPosition;
    mobileCloseButtonPosition?: CloseButtonPosition;
    desktopCloseButtonPosition?: CloseButtonPosition;
    classModal?: string;
    breakPointDesktop?: number;
    forceFixed?: boolean;
}

const AppModal: React.FC<AppModalProps> = (props: AppModalProps) => {
    const {
        id,
        children,
        disableScroll = false,
        appendTo,
        zIndex,
        mobileCloseButtonPosition = CloseButtonPosition.TOP_RIGHT,
        desktopCloseButtonPosition = CloseButtonPosition.RIGHT_TOP,
        mobilePosition = ModalPosition.BOTTOM_CENTER,
        desktopPosition = ModalPosition.CENTER,
        classModal,
        breakPointDesktop = 768,
        forceFixed = false,
    } = props;
    const {closeModal, isOpenModal} = useModal();
    const isModalOpen = isOpenModal(id);
    const [backDropPosition, setBackDropPosition] = useState({scrollOffset: "0"});
    const isDesktop = useMediaQuery({minWidth: breakPointDesktop})

    const [currentModalRoot, setCurrentModalRoot] = useState<HTMLElement | null>(document.body);

    useEffect(() => {
        if (typeof appendTo === "string") {
            const element = document.getElementById(appendTo) || document.getElementsByName(appendTo)[0] as HTMLElement;
            if (element && element !== currentModalRoot) {
                setCurrentModalRoot(element);
            }
        } else if (appendTo && "current" in appendTo && appendTo.current && appendTo.current !== currentModalRoot) {
            setCurrentModalRoot(appendTo.current);
        }
    }, [appendTo, currentModalRoot]);

    const transitionsBackdrop = useTransition(isModalOpen, {
        from: {opacity: 0},
        enter: {opacity: 0.2},
        leave: {opacity: 0},
        config: {tension: 200, friction: 30},
    });

    const transitionsModal = useTransition(isModalOpen, {
        from: {opacity: 0, transform: "scale(0.9)"},
        enter: {opacity: 1, transform: "scale(1)"},
        leave: {opacity: 0, transform: "scale(0.9)"},
        config: {tension: 100, friction: 20},
    });

    const getFlexClasses = (position: CloseButtonPosition): string => {
        switch (position) {
            case CloseButtonPosition.TOP_LEFT:
                return "flex-col justify-start items-start";
            case CloseButtonPosition.TOP_CENTER:
                return "flex-col justify-start items-center";
            case CloseButtonPosition.TOP_RIGHT:
                return "flex-col justify-start items-end";
            case CloseButtonPosition.BOTTOM_LEFT:
                return "flex-col-reverse justify-start items-start";
            case CloseButtonPosition.BOTTOM_CENTER:
                return "flex-col-reverse justify-start items-center";
            case CloseButtonPosition.BOTTOM_RIGHT:
                return "flex-col-reverse justify-start items-end";
            case CloseButtonPosition.LEFT_TOP:
                return "flex-row items-start";
            case CloseButtonPosition.LEFT_CENTER:
                return "flex-row items-center";
            case CloseButtonPosition.LEFT_BOTTOM:
                return "flex-row items-end";
            case CloseButtonPosition.RIGHT_TOP:
                return "flex-row-reverse items-start";
            case CloseButtonPosition.RIGHT_CENTER:
                return "flex-row-reverse items-center";
            case CloseButtonPosition.RIGHT_BOTTOM:
                return "flex-row-reverse items-end";
        }
    };

    useEffect(() => {
        const preventScroll = (event: Event) => {
            event.preventDefault();
        };

        if (isModalOpen && disableScroll) {
            window.addEventListener("wheel", preventScroll, {passive: false});
            window.addEventListener("touchmove", preventScroll, {passive: false});
        } else {
            window.removeEventListener("wheel", preventScroll);
            window.removeEventListener("touchmove", preventScroll);
        }

        return () => {
            window.removeEventListener("wheel", preventScroll);
            window.removeEventListener("touchmove", preventScroll);
        };
    }, [isModalOpen, disableScroll]);

    useEffect(() => {
        if (isModalOpen) {
            const updateBackdropPosition = () => {
                const scrollOffset = currentModalRoot === document.body ? window.scrollY : currentModalRoot?.scrollTop;
                setBackDropPosition({scrollOffset: `${scrollOffset || 0}`})
            };

            updateBackdropPosition();
            window.addEventListener("scroll", updateBackdropPosition);

            return () => {
                window.removeEventListener("scroll", updateBackdropPosition);
            };
        }
    }, [isModalOpen, appendTo]);

    if (!isModalOpen) return null;

    const modalContent = (
        <>
            {transitionsBackdrop(
                (styles, item) =>
                    item && (
                        <animated.div
                            style={{
                                ...styles,
                                top: `${backDropPosition.scrollOffset}px`, // dopasowuje top do przewinięcia
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: zIndex ?? 10,
                            }}
                            className="bg-special-gray inset-0 h-full absolute"
                        ></animated.div>
                    )
            )}
            {transitionsModal(
                (styles, item) =>
                    item && (
                        <animated.div
                            style={{
                                ...styles,
                                zIndex: zIndex ? (zIndex + 1) : 11,
                                ...getPositionCSS(isDesktop ? desktopPosition : mobilePosition),
                            }}
                            className={classNames(
                                "w-full md:w-fit flex items-center justify-center",
                                forceFixed ? "fixed" : "absolute"
                            )}
                        >
                            <div
                                className={classNames(
                                    "relative flex gap-4 md:gap-2 lg:max-w-lg md:max-w-md w-[100%] p-3 md:w-fit",
                                    getFlexClasses(isDesktop ? desktopCloseButtonPosition : mobileCloseButtonPosition),
                                    classModal,
                                )}
                            >
                                <div
                                    style={{zIndex: zIndex ? (zIndex + 1) : 11}}
                                    onClick={() => closeModal(id)}
                                    className="bg-white hover:text-primary-darken focus:text-primary-darken rounded-xl mx-6 md:mx-0 flex items-center justify-center min-w-8 min-h-8 w-8 h-8 cursor-pointer text-special-gray"
                                >
                                    <XMarkIcon className="w-2 h-2"/>
                                </div>
                                <div
                                    className="h-fit flex-grow w-full md:max-w-[calc(88%)] sm:min-w-fit min-h-40 bg-white rounded-3xl p-5 drop-shadow-2xl">
                                    {children}
                                </div>
                            </div>
                        </animated.div>
                    )
            )}
        </>
    );

    return ReactDOM.createPortal(modalContent, currentModalRoot!);
};

export default AppModal;
