import React, { useRef, useState } from 'react';
import { useFormContext } from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";
import { classNames } from "../../../../utils/class-names";
import { ReactComponent as CalendarIcon } from "@assets/icons/calendar.svg";
import { DateHelper } from "../../../../utils/date-helper";

interface DateTimeFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    className?: string;
    labelClassName?: string;
    isLoading?: boolean;
    isRequired?: boolean;
    onlyDate?: boolean;
    onlyTime?: boolean;
    forceDisabled?: boolean;
    canType? : boolean;
}

const DateTimeFormField: React.FC<DateTimeFormFieldProps> = (props) => {
    const { register, setValue, formState: { errors, isSubmitting, disabled } } = useFormContext();
    const {
        name,
        label,
        className = '',
        isLoading,
        labelClassName,
        isRequired = false,
        onlyDate = false,
        onlyTime = false,
        forceDisabled = false,
        placeholder = 'Select date',
        canType,
        ...rest
    } = props;

    const [selectedDate, setSelectedDate] = useState<string>('');
    const [isFocused, setIsFocused] = useState(false);
    const parentInputRef = useRef<HTMLDivElement>(null);

    const handleCalendarClick = () => {
        if (parentInputRef.current?.querySelector('input')) {
            const input = parentInputRef.current.querySelector('input') as HTMLInputElement;
            input.focus();
            input.showPicker?.();
            setIsFocused(true);
        }
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dateValue = event.target.value;
        const formattedDate = dateValue ? DateHelper.formatDate(new Date(dateValue), "DD-MM-YYYY") : null;
        setSelectedDate(formattedDate!);
        setValue(name, dateValue);
        setIsFocused(false);
    };

    const handleDateRawChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = event.target.value;
        setSelectedDate(rawValue); // Update input value directly
        setValue(name, rawValue); // Sync with form state
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const inputType = onlyDate ? 'date' : onlyTime ? 'time' : 'datetime-local';

    return (
        <div className="w-full">
            <div
                className="relative w-full h-fit"
                ref={parentInputRef}
                onClick={() => !isFocused && handleCalendarClick()}
            >
                <label
                    htmlFor={name}
                    className={classNames(
                        "absolute block text-xs font-medium top-2 left-[14px]",
                        (forceDisabled || disabled) && "text-gray"
                    )}
                >
                    {label}
                    {isRequired && <span className="text-red-500">*</span>}
                </label>
                {isLoading ? (
                    <SkeletonFormField />
                ) : (
                    <>

                        {canType ? 
                        (<>
                            <input
                                type={inputType}
                                {...register(name)}
                                value={selectedDate}
                                onChange={handleDateRawChange}
                                onFocus={() => setIsFocused(true)}
                                onBlur={handleBlur}
                                className={classNames(
                                    `border-2 bg-transparent rounded-2xl px-3 py-2 pr-14 pt-6 w-full min-h-[62px] cursor-text transition-all outline-none
                                     ${isFocused ? 'ring-2 ring-primary' : ''}
                                     ${errors[name] ? 'border-red-400' : 'border-gray-light'} ${className}`,
                                    (forceDisabled || disabled) && "bg-gray-light text-gray"
                                )}
                                pattern='\d{4}-\d{2}-\d{2}'
                                disabled={isSubmitting || forceDisabled || disabled}
                                {...rest}
                            />


                        </>) : 
                        (<>
                            <input
                                type={inputType}
                                {...register(name)}
                                onChange={handleDateChange}
                                onFocus={() => setIsFocused(true)}
                                onBlur={handleBlur}
                                className="absolute opacity-0 h-0 w-0 pointer-events-none"
                                disabled={isSubmitting || forceDisabled || disabled}
                                {...rest}
                            />
                            <div className={classNames(`
                                border-2 rounded-2xl px-3 py-2 pr-14 pt-6 w-full cursor-pointer transition-all
                                 ${isFocused && 'ring-2 ring-primary'}
                                 ${errors[name] ? 'border-red-400' : 'border-gray-light'} ${className},
                                (forceDisabled || disabled) && "bg-gray-light text-gray",
                                selectedDate ? "text-black" : "text-gray"`
                            )}
                        >
                            {selectedDate || placeholder}
                            </div>
                        </>)
                        
                        
                        }

                        {/* Visible Input for Typing */}
                        
                    </>
                )}
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                    <div className="bg-gray-light rounded-2xl w-fit h-fit p-[14px]">
                        <CalendarIcon
                            className={`h-[0.75rem] w-[0.75rem] ${
                                errors[name] ? 'text-red-500' : 'text-special-gray'
                            }`}
                        />
                    </div>
                </div>
            </div>
            {errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default DateTimeFormField;
