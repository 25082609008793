import WrapperCard from "../../common/components/wrapper-card";
import {ReactComponent as EditIcon} from "@assets/icons/edit.svg";
import {ReactComponent as CrossIcon} from "@assets/icons/cross.svg";
import {ReactComponent as DragAndDropIcon} from "@assets/icons/drag-n-drop.svg";
import {ReactComponent as CheckIcon} from "@assets/icons/check.svg";
import {ReactComponent as ReplyIcon} from "@assets/icons/reply.svg";
import React, {useContext, useMemo, useState} from "react";
import {DigitalProductCategory} from "../../core/models/interfaces/digital-product-category.interface";
import {SessionCreatorToolsContext} from "../contexts/session-creator-tools-context";
import DescriptionFormField from "../../common/components/forms/description-form-field";
import {FormProvider, useForm} from "react-hook-form";
import {CategoryProductFormBody} from "../models/interfaces/category-product-form-body.interface";
import {yupResolver} from "@hookform/resolvers/yup";
import {schemaAddCategoryProduct} from "../constants/schema-add-category-product";
import {hasProductsInCategory} from "../utils/has-products-in-category";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import {hasAllDeletedProductsInCategory} from "../utils/has-all-deleted-products-in-category";

interface CategoryProductCardProps {
    products: DigitalProduct[],
    category: DigitalProductCategory,
    disableDelete?: boolean,
    disableEdit?: boolean,
    onDelete?: (categoryId: string) => void;
    onUpdate?: (category: DigitalProductCategory) => void;
    showDraggableIcon?: boolean,
}

const CategoryProductCard = (props: CategoryProductCardProps) => {
    const {
        category,
        showDraggableIcon = false,
        disableDelete = false,
        disableEdit = false,
        onDelete,
        onUpdate,
        products
    } = props;
    const methods = useForm<CategoryProductFormBody>({
        defaultValues: {
            categoryName: category.name!,
        },
        resolver: yupResolver(schemaAddCategoryProduct),
        mode: 'onSubmit',
    });
    const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
    const {editedCategory, setEditedCategory} = useContext(SessionCreatorToolsContext)!;
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const handleDeleteClick = (): void => {
        if (!canDelete) {
            setShowDeleteWarning(true);
            setTimeout(() => setShowDeleteWarning(false), 4000);
        } else if (onDelete) {
            onDelete(category.id!);
        }
    };

    const handleUpdate = async (): Promise<void> => {
        await methods.trigger();
        if (!methods.formState.isValid) return;

        const updatedCategory: DigitalProductCategory = {
            ...category,
            name: methods.getValues("categoryName"),
        };
        onUpdate && onUpdate(updatedCategory);
        setIsEditMode(false);
        setEditedCategory(null);
    };

    const handleSwapEditMode = (value: boolean): void => {
        value ? setEditedCategory(category) : setEditedCategory(null);
        setIsEditMode(value);
        methods.setValue("categoryName", category.name!);
    };

    const isOtherCategoryBeingEdited: boolean = useMemo(() => !!editedCategory && (editedCategory.id !== category.id), [editedCategory]);
    const canDelete: boolean = !hasProductsInCategory(category.id!, products) || hasAllDeletedProductsInCategory(category.id!, products);

    return (
        <div>
            {isEditMode ? (
                <div className="w-full">
                    <FormProvider {...methods}>
                        <DescriptionFormField
                            name="categoryName"
                            label="Name"
                            maxLength={35}
                            className="!pr-2 !w-full"
                            placeholder="Category name"
                            suffix={
                                <div className="flex items-start">
                                        <span
                                            onClick={handleUpdate}
                                            className="p-2 rounded-lg cursor-pointer hover:bg-gray-light focus:bg-gray-light">
                                            <CheckIcon className="w-[12px] h-[12px] min-w-[12px] text-special-gray"/>
                                        </span>
                                    <span
                                        onClick={handleSwapEditMode.bind(this, false)}
                                        className="p-2 rounded-lg cursor-pointer hover:bg-gray-light focus:bg-gray-light">
                                            <ReplyIcon className="w-[10px] h-[10px] min-w-[10px] text-special-gray"/>
                                        </span>
                                </div>
                            }
                        />
                    </FormProvider>
                </div>
            ) : (
                <WrapperCard
                    className="overflow-hidden relative border-gray-light !rounded-2xl h-12 max-h-12 w-full flex items-center justify-between pr-1 cursor-grab bg-white">
                    {isOtherCategoryBeingEdited && (
                        <div
                            className="absolute inset-0 bg-white/80 backdrop-blur-[1px] z-10 rounded-2xl">
                        </div>
                    )}

                    <div className="flex gap-3 items-center">
                        {showDraggableIcon && (
                            <span>
                            <DragAndDropIcon className="w-[6.67px] h-[10px] text-special-gray"/>
                        </span>
                        )}
                        <span className="text-black font-semibold text-sm leading-[1.05rem] select-none">
                            {category.name}
                        </span>
                    </div>

                    <div className="flex items-center">
                        {!disableEdit && (
                            <span
                                onClick={handleSwapEditMode.bind(this, true)}
                                className="p-3 rounded-xl cursor-pointer hover:bg-gray-light focus:bg-gray-light">
                                    <EditIcon className="w-[12px] h-[12px] min-w-[12px] text-special-gray"/>
                                </span>
                        )}
                        {!disableDelete && (
                            <span
                                onClick={handleDeleteClick}
                                className="p-3 rounded-xl cursor-pointer hover:bg-gray-light focus:bg-gray-light">
                                    <CrossIcon className="w-[8px] h-[8px] min-w-[8px] text-special-gray"/>
                                </span>
                        )}
                    </div>
                </WrapperCard>
            )}
            {showDeleteWarning && (
                <span
                    className="transition mt-1 text-semantic-warning text-[0.625rem] block bg-semantic-warning-pastel p-1 px-4 rounded-xl">
                    Category <strong>{category.name}</strong> has products and cannot be deleted.
                </span>
            )}
        </div>
    );
};

export default CategoryProductCard;
