import React from 'react';
import {useFormContext} from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";
import {classNames} from "../../../../utils/class-names";
import {ReactComponent as CoinIcon} from "@assets/icons/coin.svg";

interface NumberFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    min?: number;
    max?: number;
    step?: number;
    isLoading?: boolean;
    isRequired?: boolean;
    showArrows?: boolean;
    formatValue?: (value: number) => string;
    className?: string;
    forceDisabled?: boolean,
    suffix?: string;
    isYcoin?: boolean;
}

const NumberFormField: React.FC<NumberFormFieldProps> = (props: NumberFormFieldProps) => {
    const {register,setValue, formState: {errors, isSubmitting, disabled}} = useFormContext();
    const {
        name,
        label,
        min,
        max,
        step = 1,
        isLoading = false,
        isRequired = false,
        showArrows = false,
        formatValue,
        className = '',
        maxLength,
        forceDisabled = false,
        suffix,
        isYcoin,
        ...rest
    } = props;

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = parseFloat(e.target.value);

        if (isNaN(value) && min) {
            value = min;
        }

        if (min && (value < min)) value = min;
        if (max && (value > max)) value = max;

        if (maxLength && value.toString().length > maxLength) {
            value = parseFloat(value.toString().slice(0, maxLength));
        }

        setValue(name, value || 0);
        rest.onInput && rest.onInput(e);
    };

    return (
        <div className='w-full'>
            <div className={`relative w-full h-fit`}>
                <label htmlFor={name} className={classNames(
                    "absolute block text-xs font-medium top-2 left-[14px]",
                    (forceDisabled || disabled) && "text-gray"
                )}>
                    {label}
                    {isRequired && <span className='text-red-500'>*</span>}
                </label>
                {isLoading
                    ? <SkeletonFormField/>
                    : (
                        <>
                            <input
                                type="number"
                                id={name}
                                min={min}
                                max={max}
                                step={step}
                                {...register(name)}
                                className={classNames(
                                    `border-2 border-gray-light rounded-2xl px-3 py-2 pt-6 appearance-none w-full focus:outline-none focus:ring-2 focus:ring-primary ${errors[name] ? 'border border-red-400 focus:ring-red-400' : 'border-gray-300 focus:ring-primary_400'} ${className}`,
                                    !showArrows && 'disable-arrows-input-number',
                                    (forceDisabled || disabled) && "bg-gray-light text-gray"
                                )}
                                disabled={isSubmitting || forceDisabled || disabled}
                                onInput={handleInput}
                                {...rest}
                            />
                            {isYcoin && (
                                <span className="absolute right-3 top-2/3 transform -translate-y-1/2 text-gray-500">
                                    <CoinIcon/>
                                </span>

                            )}
                            {suffix && !isYcoin && (
                                <span className="absolute right-3 top-2/3 transform -translate-y-1/2 text-gray-500">
                                    {suffix}
                                </span>

                            )}

                            {showArrows && (
                                <div className="absolute right-2 top-2 flex flex-col">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const currentValue = parseFloat(rest.value as string) || 0;
                                            const newValue = formatValue ? formatValue(currentValue + step) : currentValue + step;
                                            // @ts-ignore
                                            rest.onChange && rest.onChange(newValue);
                                        }}
                                        className="text-gray-500 hover:text-black">
                                        ↑
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const currentValue = parseFloat(rest.value as string) || 0;
                                            const newValue = formatValue ? formatValue(currentValue - step) : currentValue - step;
                                            // @ts-ignore
                                            rest.onChange && rest.onChange(newValue);
                                        }}
                                        className="text-gray-500 hover:text-black">
                                        ↓
                                    </button>
                                </div>
                            )}
                        </>
                    )}
            </div>
            {errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default NumberFormField;
