import React, {useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import TextFormField from '../../common/components/forms/text-form-field';
import PasswordFormField from "../../common/components/forms/password-form-field";
import AppButton from "../../common/components/app-button";
import useAuth from "../hooks/use-auth";
import {RegisterBody} from "../models/interfaces/register-body.interface";
import {ReactComponent as ChevronLeftIcon} from "@assets/icons/chevron-left.svg";
import {schemaRegister} from "../constants/schema-register";
import Tooltip from "../../common/components/tooltip";


const RegisterForm = () => {
    const {register, loading, login, currentUser} = useAuth();
    const formRef = useRef<HTMLFormElement>(null);
    const methods = useForm<RegisterBody>({
        resolver: yupResolver(schemaRegister),
        mode: 'onSubmit',
    });
    const navigate = useNavigate();

    const handleRegister = async () => {
        await methods.trigger();

        if (!methods.formState.isValid) {
            return;
        }

        const body = methods.getValues();
        try {
            await register(body);
            await handleSuccessRegister();
        } catch (error) {
            console.error("Register failed:", error);
        }
    };

    const handleSuccessRegister = async () => {
        const body = methods.getValues();
        try {
            await login(body.email, body.password);
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    return (
        <FormProvider {...methods}>
            <form ref={formRef} onSubmit={methods.handleSubmit(handleRegister)}
                  className="flex flex-col gap-y-6 p-4 pt-10 max-w-md mx-auto h-full min-h-fit">

                <div className='flex-grow md:flex-grow-0 space-y-4'>
                    <div className="flex items-center gap-4 !mb-6">
                        <button
                            type='button'
                            className='p-3 w-10 h-10 bg-gray-light flex items-center justify-center rounded-2xl text-special-gray'
                            onClick={() => navigate("/welcome")}>
                            <ChevronLeftIcon className='w-2 h-3'/>
                        </button>
                        <h2 className="text-[1.75rem] md:text-[2.5rem] leading-[2.1rem] md:leading-[3rem] font-bold">Create account</h2>
                    </div>

                    {/*<div className='flex flex-col gap-y-[2px]'>*/}
                    {/*    <ButtonGoogle/>*/}
                    {/*    <ButtonFacebook/>*/}
                    {/*    <ButtonInstagram/>*/}
                    {/*</div>*/}


                    {/*<div className='flex gap-3 items-center'>*/}
                    {/*    <div className='w-full border-b border-gray'></div>*/}
                    {/*    <span className='font-semibold text-special-gray text-xs'>OR</span>*/}
                    {/*    <div className='w-full border-b border-gray'></div>*/}
                    {/*</div>*/}

                    <div className="space-y-3">
                        <TextFormField name="email" label="E-mail" placeholder="Write"/>

                        <PasswordFormField name="password" label="Password" placeholder="Write"
                                           showPasswordToggle={true}/>

                        <PasswordFormField name="confirmPassword" label="Confirm password" placeholder="Write"
                                           showPasswordToggle={true}/>
                    </div>

                    <div className="flex text-special-gray text-xs leading-[0.9rem] gap-1 !mt-5">
                        <Tooltip/>
                        <span>
                            By using this site, you acknowledge you are at least 18 years old and you agree to our
                            {' '}
                            <a
                                href="https://about.yurs.io/terms/"
                                target="_blank"
                                className="underline cursor-pointer hover:text-primary-darken">
                                Terms of Service
                            </a>
                            {' '}
                            and
                            {' '}
                            <a
                                href="https://about.yurs.io/privacy/"
                                target="_blank"
                                className="underline cursor-pointer hover:text-primary-darken">
                                Privacy Policy
                            </a>
                      </span>
                    </div>
                </div>

                <AppButton
                    type='submit'
                    loading={loading}
                    label="Next"
                    className='w-full text-white text-sm py-[9px] h-fit font-semibold bg-primary !rounded-2xl'/>
            </form>
        </FormProvider>
    );
};

export default RegisterForm;
