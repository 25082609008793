import React, {useRef} from "react";
import ProfileBackground from "../components/profile-background";
import ProfileImage from "../components/profile-image";
import ShortUserData from "../components/short-user-data";
import {Uploady} from "@rpldy/uploady";
import {useProfileForm} from "../hooks/use-profile-form";
import TextFormField from "../../common/components/forms/text-form-field";
import {FormProvider} from "react-hook-form";
import DescriptionFormField from "../../common/components/forms/description-form-field";
import useAuth from "../../auth/hooks/use-auth";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import ProfileFanMenu from "../components/profile-fan-menu";
import TextareaFormField from "../../common/components/forms/textarea-form-field";

const OwnProfile = () => {
    const {methods} = useProfileForm();
    const refParentStickyActionBar = useRef<HTMLDivElement>(null);
    const {currentUser, hasAnyAuthorities} = useAuth();

    const presentedNameUser = !currentUser?.firstname || !currentUser?.lastname ? currentUser?.username! : currentUser.firstname + " " + currentUser?.lastname


    return (
        <div className='bg-white rounded-t-[1.5rem] !max-w-full'>
            <div className='relative inset-0 h-[260px]'>
                <Uploady autoUpload={false}>
                    <ProfileBackground
                        canShowMenuHamburger={true}
                        isEditable={true}
                        className='lg:rounded-3xl md:rounded-none'
                        onChange={(imageSrc) => methods.setValue('backgroundProfileImage', imageSrc, {shouldDirty: true})}
                        backgroundImage={currentUser?.backgroundProfileImage as string}/>
                </Uploady>
            </div>
            <div className='relative' ref={refParentStickyActionBar}>
                <div
                    className='p-4 pb-0 md:pb-4 w-full h-full bg-white rounded-t-[2rem] relative top-[-30px] left-0 md:flex max-w-[832px] gap-4 md:mx-auto md:top-0'>
                    <div className='static md:sticky top-[80px] left-0 h-fit min-w-[250px] md:max-w-[250px]'>
                        <div
                            className='absolute top-[-60px] md:top-[-80px] left-1/2 md:left-0 translate-x-[-50%] md:translate-x-0 w-fit'>
                            <Uploady autoUpload={false}>
                                <ProfileImage
                                    isEditable={true}
                                    avatar={currentUser?.profileImage as string}
                                    onChange={(imageSrc) => methods.setValue('profileImage', imageSrc, {shouldDirty: true})}
                                    role={currentUser?.authority!}/>
                            </Uploady>
                        </div>
                        <div className='mt-[80px] md:mt-[65px]'></div>
                        <ShortUserData
                            isEditable={true}
                            isOwnProfile={true}
                            fullName={presentedNameUser}
                            shortDescription={''}
                            username={currentUser?.username + ''}
                        />
                    </div>
                    <div>
                        {hasAnyAuthorities([UserAuthority.FAN]) && (
                            <ProfileFanMenu/>
                        )}
                        <FormProvider {...methods}>
                            <form className='w-full overflow-hidden flex gap-4 flex-col pt-9 pb-28 md:pb-16 px-[2px]'>
                                <div className='flex gap-4 w-full flex-grow md:flex-nowrap flex-wrap'>
                                    <TextFormField name='firstname' label='First name' placeholder='Write first name...'/>
                                    <TextFormField name='lastname' label='Last name' placeholder='Write last name...'/>
                                </div>
                                {/*<TextFormField name='dateOfBirth' label='Date of birth'/>*/}
                                <TextFormField name='username' label='Username' placeholder='Write username...'/>
                                {/*<TextFormField name='email' label='E-mail' placeholder='Write e-mail...'/>*/}
                                {/*<NumberFormField name='phoneNumber' label='Phone number' placeholder='Write phone number...' maxLength={9}/>*/}
                                {/*<TextFormField name='gender' label='Gender' forceDisabled={true} />*/}
                                {/*<TextFormField name='shortBio' label='Short bio' placeholder='Write short bio...'/>*/}
                                <DescriptionFormField name='bio' label='Short bio' maxLength={80} placeholder='Write bio...'/>
                                <TextareaFormField name={"longBio"} label={"Bio"} maxChar={500}/>
                            </form>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OwnProfile
