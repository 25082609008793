import {forwardRef, ReactNode} from "react";


type AppWrapperProps = {
    children: ReactNode;
    className?: string;
};

const AppWrapper = forwardRef<HTMLDivElement, AppWrapperProps>(({children, className}, ref) => {
    return (
        <div ref={ref} className={'!overscroll-none mx-auto relative overflow-x-clip max-h-lvh' + className}>
            {children}
        </div>
    )
});

export default AppWrapper
