import React from 'react';
import {useNavigate} from "react-router-dom";
import AppButton from "../features/common/components/app-button";
import {ReactComponent as IllustrationLandingPage} from "@assets/illustrations/illustration-landing-page.svg"
import Logo from "../features/core/components/ui/logo";
import AppWrapper from "../layout/components/app-wrapper";
import useAuth from "../features/auth/hooks/use-auth";
import FooterLinks from "../features/core/components/footer-links";

const AppLandingPage: React.FC = () => {
    const navigate = useNavigate();
    const {isAuthenticated} = useAuth();

    if (isAuthenticated) navigate("/")

    const handleGoToLogin = (): void => {
        navigate("/auth/login");
    };

    const handleGoToRegister = (): void => {
        navigate("/auth/register");
    };

    return (
        <AppWrapper className='min-h-dvh flex flex-col justify-between'>
            <div className="flex flex-col h-full max-w-full flex-1">
                <div
                    className='bg-[#5E17EB12] absolute top-0 left-1/2 -translate-x-1/2 w-[135%] md:w-[180%] h-[200px] md:h-[300px] '
                    style={{borderRadius: "0 0 50% 50% / 0 0 300px 300px"}}>
                </div>

                <div
                    className="w-screen md:mb-8 flex flex-col-reverse md:flex-col gap-y-3 md:gap-y-12 justify-between items-center relative pt-[3.5rem]">
                    <Logo type='complete' className='w-auto max-h-[35px] md:max-h-[45px]'/>
                    <IllustrationLandingPage/>
                </div>

                <div
                    className='p-4 pb-0 flex flex-col justify-between md:justify-start max-w-md mx-auto h-full flex-1 md:flex-grow-0 flex-grow w-full'>
                    <div className="flex flex-col mb-6 justify-center text-center space-y-2 md:space-y-4">
                        <h1 className="text-[1.75rem] md:text-[2.5rem] font-bold text-black leading-[2.1rem] md:leading-[3rem]">
                            Welcome to Yurs
                        </h1>
                        <p className="text-sm md:text-base font-medium text-special-gray leading-[1.315rem] md:leading-6 md:text-black max-w-[250px] md:max-w-[310px] mx-auto">
                            Where creators and fans connect like never before.
                        </p>
                    </div>

                    <div className="flex flex-col md:flex-row gap-2 w-full items-center">
                        <AppButton
                            type='button'
                            label='Sign in'
                            onClick={handleGoToLogin}
                            className='w-full text-special-gray focus:text-primary hover:text-primary border-special-gray
                            text-sm py-[9px] h-fit !rounded-2xl font-semibold bg-transparent whitespace-nowrap'/>
                        <AppButton
                            type='button'
                            label='Create an account'
                            onClick={handleGoToRegister}
                            className='w-full text-white text-sm py-[9px] h-fit !rounded-2xl font-semibold
                             bg-primary whitespace-nowrap'/>
                    </div>
                </div>
            </div>
            <FooterLinks/>
        </AppWrapper>
    );
};

export default AppLandingPage;
